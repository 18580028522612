/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import { useIsIE } from "./helper"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import OverlayNav from "./overlayNav"
import Bugherd from "./bugherd"


import "../css/main.scss";




const Layout = ({ location, data, lang, scrollMagic, activeKey, children, hideFooter }) => {
  const [showOverlayNav, setShowOverlayNav] = useState(false);
  const isIE = useIsIE();
  
  function onHideOverlayNav() {
    setShowOverlayNav(false);
  }
  function onShowOverlayNav() {
    setShowOverlayNav(true);
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          class: isIE && 'no-scrollmagic'
        }}
      />
      <Header location={location} data={data} lang={lang} onShowOverlayNav={() => onShowOverlayNav()} activeItem={activeKey}/>

      

      {children}

      
      { !hideFooter && <Footer location={location} data={data} lang={lang} scrollMagic={scrollMagic} />}

      {showOverlayNav && <OverlayNav location={location} data={data} lang={lang} onHideOverlayNav={() => onHideOverlayNav()} />}

      <Bugherd />
      
    </>
  )
}


Layout.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
