import React from "react"

import { Link } from "gatsby"
import LangSwitch from "./langSwitch"
import { rootShowcasesPath, rootServicesPath, blogPath, rootCTAPath } from "../utils/linkResolver";





const OverlayNav = ({ location, data, lang, onHideOverlayNav }) => {
  const t = data.prismic.allHeaders.edges[0].node;

  document.body.classList.add('overlay-nav-shown')

  function hideOverlayNavHandler() {
    document.body.classList.remove('overlay-nav-shown')
    onHideOverlayNav()
  }

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div className="overlay-nav" onClick={() => hideOverlayNavHandler()} >
  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
    <button className="overlay-nav-close" onClick={() => onHideOverlayNav()} onKeyDown={(ev) => {if(ev.keyCode === 13){ hideOverlayNavHandler()}}}></button>
    <div></div>
    <nav className="overlay-nav-nav">
      <Link className="overlay-nav-nav-item" to={rootShowcasesPath({lang: lang})} dangerouslySetInnerHTML={{__html: t.nav_showcases}} />
      <Link className="overlay-nav-nav-item" to={rootServicesPath({lang: lang})} dangerouslySetInnerHTML={{__html: t.nav_services}} />
      <Link className="overlay-nav-nav-item" to={blogPath({lang: lang})} dangerouslySetInnerHTML={{__html: t.nav_blog}} />
      <Link className="overlay-nav-nav-item" to={rootCTAPath({lang: lang})} dangerouslySetInnerHTML={{__html: t.nav_contact}} />
    </nav> 

    <LangSwitch location={location} lang={lang} className="overlay-nav-language-switch" />
  </div>
}

export default OverlayNav;