import React, {useEffect} from "react"
import PropTypes from "prop-types"

// import {Link} from "gatsby"

import LangSwitch from "./langSwitch"
import { blogPath, rootShowcasesPath, rootCTAPath, rootServicesPath } from "../utils/linkResolver"

import {TikLink} from "./helper";

import IconSmartphone from "../images/icons/smartphone.inline.svg"
// import IconEmail from "../images/icons/email.inline.svg"
import IconFacebook from "../images/icons/facebook.inline.svg"
import IconInstagram from "../images/icons/instagram.inline.svg"
import IconLinkedIn from "../images/icons/linked-in.inline.svg"
import IconTickaroo from "../images/icons/tickaroo.inline.svg"
import IconTwitter from "../images/icons/twitter.inline.svg"
import IconXing from "../images/icons/xing.inline.svg"
import IconYoutube from "../images/icons/youtube.inline.svg"


const Footer = ({ location, data, lang, scrollMagic }) => {
  const t = data.prismic.allFooters.edges[0].node;
  

  const sm = [
    {key: 'sm-facebook', icon: <IconFacebook />,  title: 'Facebook',  url: 'https://www.facebook.com/tickaroo'},
    {key: 'sm-linked-in', icon: <IconLinkedIn />,  title: 'LinkedIn',  url: 'https://www.linkedin.com/company/tickaroo'},
    {key: 'sm-instagram', icon: <IconInstagram />, title: 'Instagram', url: 'https://www.instagram.com/tickaroo/'},
    {key: 'sm-xing', icon: <IconXing />,      title: 'Xing',      url: 'https://www.xing.com/companies/tickaroogmbh?sc_o=da980_e'},
    {key: 'sm-youtube', icon: <IconYoutube />,   title: 'Youtube',   url: 'https://www.youtube.com/channel/UCqO0VxjS4W7FZZdi8F9eZFg'},
    {key: 'sm-tickaroo', icon: <IconTickaroo />,  title: 'Tickaroo',  url: 'https://www.tickaroo.com/about/de/news.html'},
    {key: 'sm-twtitter', icon: <IconTwitter />,   title: 'Twitter',   url: 'https://twitter.com/tickaroo'}
  ]


  useEffect(() => {    
    if(scrollMagic) {
      const section = document.querySelector('.footer[data-wipe]');

      new scrollMagic.instance.Scene({
        triggerElement: section,
        triggerHook: "onLeave",
        duration: "100%"
      })
      .setPin(section, {pushFollowers: false})
      .addTo(scrollMagic.controller);
    }
  });

  return (
    <>
    <footer className="footer" data-wipe>

      <div className="footer-main-wrapper">
        <div className="footer-main row">

          <div className="col-12">
            <div className="row">
              <div className="col-12 col-bpf-6">
              <h6>{ t.apps_headline }</h6>
                <div>{ t.apps_text }</div>
                <a href="https://www.tickaroo.com/about/de/apps.html" className="footer-download-apps">
                  <IconSmartphone />
                  <div>
                    { t.apps_download }<br />
                    <span>{ t.apps_download_for }</span>
                  </div>
                </a>
              </div>
              <div className="col-12 col-bpf-6">
                <h6>We feel so social</h6>
                <nav className="social-media-nav">
                  {sm.map(item => <a key={item.key} className="social-media-item" href={item.url} title={item.title}>{item.icon}</a>)}
                </nav>
              </div>
            </div>
          </div>


          <div className="col-12 col-sm-4 col-bpf-3">
            <h6>{ t.madeby_headline }</h6>
            <nav className="footer-nav">
              <TikLink to={rootShowcasesPath({lang: lang})}>{ t.madeby_showcases }</TikLink>
              <TikLink to={rootServicesPath({lang: lang})}>{ t.madeby_services }</TikLink>
              <TikLink to={blogPath({lang: lang})}>{ t.madeby_blog }</TikLink>
              <TikLink to={rootCTAPath({lang: lang})}>{ t.madeby_contact }</TikLink>
            </nav>
          </div>


          <div className="col-12 col-sm-4 col-bpf-3">
            <h6>{ t.liveblog_headline }</h6>
            <nav className="footer-nav">
              <TikLink href={ t.liveblog_about_url}>{ t.liveblog_about }</TikLink>
              <TikLink href={ t.liveblog_trial_url}>{ t.liveblog_trial }</TikLink>
              <TikLink href={ t.liveblog_success_stories_url }>{ t.liveblog_success_stories }</TikLink>
              <TikLink href={ t.liveblog_pricing_url }>{ t.liveblog_pricing }</TikLink>
              <TikLink href={ t.liveblog_documentation_url }>{ t.liveblog_documentation }</TikLink>
            </nav>
          </div>


          <div className="col-12 col-sm-4 col-bpf-3">
            <h6>{ t.livepush_headline }</h6>
            <nav className="footer-nav">
              <TikLink href={ t.livepush_service_url }>{ t.livepush_service }</TikLink>
              <TikLink href={ t.livepush_features_url }>{ t.livepush_features }</TikLink>
              <TikLink href={ t.livepush_pricing_url }>{ t.livepush_pricing }</TikLink>
            </nav>
          </div>


          <div className="col-12 col-sm-4 col-bpf-3">
            <h6>{ t.about_headline }</h6>
            <nav className="footer-nav">
              <TikLink href={ t.about_about_url }>{ t.about_about }</TikLink>
              <TikLink href={ t.about_jobs_url }>{ t.about_jobs }</TikLink>
              <TikLink href={ t.about_press_url }>{ t.about_press }</TikLink>
              <TikLink href={ t.about_faq_support_url }>{ t.about_faq_support }</TikLink>
              <TikLink href={ t.about_contact_url }>{ t.about_contact }</TikLink>
            </nav>
          </div>

        </div>
      </div>


      <div className="footer-slim">
        <div className="footer-copyright">
          <span dangerouslySetInnerHTML={{__html: t.slim_copyright.replace('%{currentYear}', new Date().getFullYear())}} />
        </div>
        <nav className="footer-subnav">
          <TikLink className="footer-subnav-item" href={ t.slim_data_privacy_url }>{ t.slim_data_privacy }</TikLink>
          <TikLink className="footer-subnav-item" href={ t.slim_imprint_url }>{ t.slim_imprint }</TikLink>
          <TikLink className="footer-subnav-item" href={ t.slim_terms_and_conditions_url }>{ t.slim_terms_and_conditions }</TikLink>
        </nav>
        <LangSwitch location={location} lang={lang} className="footer-language-switch" />
      </div>
    </footer>
    </>
  )
}


Footer.propTypes = {
  data: PropTypes.object.isRequired,
  lang: PropTypes.string
}


export default Footer;