import React, { useState, useEffect } from "react"
import {Link} from "gatsby"
// import SVG from 'react-inlinesvg';

import LinkIconArrowRight from "../images/link-arrow-right.inline.svg";




const TikLink = ({to, href, className, children, ...opts}) => {
  const classNames = ['link link-arrow-right', className].join(' ');

  return to ? (
    <Link to={to} className={classNames} {...opts}>
      <div className="link-icon"><LinkIconArrowRight /></div>
      {children}
    </Link>
  ) : (
    <a href={href || "#"} className={classNames} {...opts}>
      <div className="link-icon"><LinkIconArrowRight /></div>
      {children}
    </a>
  )
}

const Quote = ({quote, author, className, children, ...opts}) => {
  // TODO: Translate author
  const classNames = ['quote', className].join(' ');
  return <div className={classNames}>
    <blockquote className="quote-quote">
      { children }
      <div className="quote-mark"></div>
      <div className="quote-mark quote-mark-out"></div>
    </blockquote>
    <div className="quote-author">{author}</div>
  </div>
}

const TechList = ({technology, className, children, ...opts}) => {
  const classNames = ['tech-list', className].join(' ');
  return <div className={classNames} {...opts}>
    { children }
  </div>
}

const TechListItem = ({technology, className, ...opts}) => {
  const classNames = ['tech-item', className].join(' ');
  return <div className={classNames}  key={`tech-item-${technology._meta.uid}`} {...opts}>
    {/* { (technology.logoSharp || {}).extension == 'svg' && <SVG className="tech-item-logo" src={ technology.logo.url } />} */}
    <div className="tech-item-name">{ technology.name }</div>
  </div>
}


const useIsIE = () => {
  const [isIE, setIsIE] = useState();
  useEffect(() => {
    // ie6-11
    setIsIE(/*@cc_on!@*/false || !!document.documentMode);
  }, []);
  return isIE;
}



export {
  Quote,
  TikLink,
  TechList,
  TechListItem,
  useIsIE
}