import React from "react"
import {Link} from "gatsby"
import PropTypes from "prop-types"

import { switchLanguagePath } from "../utils/linkResolver"

const LangSwitch = ({ location, className, lang }) => {

  return <div className={`language-switch ${className}`}>
    <Link
      to={switchLanguagePath(location, {lang: 'de'})}
      className={`language-switch-item ${lang && lang.startsWith('de') && "active"}`}
    >
      <span>DE</span>
    </Link>

    <Link
      to={switchLanguagePath(location, {lang: 'en'})}
      className={`language-switch-item ${lang && lang.startsWith('en') && "active"}`}
    >
      <span>EN</span>
    </Link>
  </div>
}

LangSwitch.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.string
}


export default LangSwitch;