import React from "react"
import { Helmet } from "react-helmet"

const Bugherd = () => {
  return (
    <React.Fragment>
      { (process.env.GATSBY_ENABLE_BUGHERD === 'true') && 
        <Helmet>
          <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=nedzv6xjm4dxrev9hlloja" async="true"></script>
        </Helmet>
      }
    </React.Fragment>
  )
}

export default Bugherd;