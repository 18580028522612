import {Link} from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react";

import Headroom from "react-headroom"

// import scrollTo from 'gatsby-plugin-smoothscroll';
import LangSwitch from "./langSwitch"
import { blogPath, rootPath, rootShowcasesPath, rootCTAPath, rootServicesPath } from "../utils/linkResolver"
import logo from "../images/made-by-logo-white.svg"

const Header = ({ location, data, lang, onShowOverlayNav, activeItem }) => {
  const t = data.prismic.allHeaders.edges[0].node

  const [ scrolled, setScrolled ] = useState()

  useEffect(_ => {
    const handleScroll = _ => {
      // console.log(window.pageYOffset);
      if (window.pageYOffset > 100) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  function activeClass(key) {
    return key === activeItem ? 'active' : '';
  }

  return (
    <Headroom disableInlineStyles>
      <header className="header">
        <div className="header-container">
          <div>
            <Link to={rootPath({lang: lang})} className="made-by-logo">
              <img alt="made.by Logo" src={logo} className={scrolled ? "" : "scrolled-display-none"}></img>
            </Link>
          </div>

          <div className="header-right">
            <nav className="header-nav">
              <Link to={rootShowcasesPath({lang: lang})} className={`header-nav-item header-nav-item-showcases ${activeClass('showcases')}`}>
                <span dangerouslySetInnerHTML={{__html: t.nav_showcases}} />
              </Link>
              <Link to={rootServicesPath({lang: lang})} className={`header-nav-item header-nav-item-services ${activeClass('services')}`}>
                <span dangerouslySetInnerHTML={{__html: t.nav_services}} />
              </Link>
              <Link to={blogPath({lang: lang})} className={`header-nav-item header-nav-item-blog ${activeClass('blog')}`}>
                <span dangerouslySetInnerHTML={{__html: t.nav_blog}} />
              </Link>
              <Link to={rootCTAPath({lang: lang})} className={`header-nav-item header-nav-item-contact ${activeClass('kontakt')}`}>
                <span dangerouslySetInnerHTML={{__html: t.nav_contact}} />
              </Link>
            </nav>
            <button className="mobile-nav-toggle" onClick={() => onShowOverlayNav()} >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        <LangSwitch location={location} lang={lang} className="header-lang-switch" />
      </header>
    </Headroom>
  )
};

Header.propTypes = {
  data: PropTypes.object.isRequired,
  lang: PropTypes.string,
  onShowOverlayNav: PropTypes.func,
  activeItem: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

// export const query = graphql`
  

//   fragment PrismicLayout on PRISMIC {
//     allHeaders(lang: "de-de") {
//       edges {
//         node {
//           nav_showcases
//           nav_services
//           nav_blog
//           nav_contact
//         }
//       }
//     }
//     allFooters(lang: "de-de") {
//       edges {
//         node {
//           apps_download
//           apps_download_for
//           apps_headline
//           apps_text
//           imprint
//           liveblog_about
//           liveblog_headline
//           madeby_blog
//           liveblog_trial
//           madeby_contact
//           madeby_headline
//           madeby_showcases
//           madeby_services
//           privacy_policy
//           social_headline
//           terms
//         }
//       }
//     }
//   }
// `


export default Header;
